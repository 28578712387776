<template>
  <v-app-bar fixed app elevate-on-scroll class="align-center pr-1">
    <v-btn class="mt-2" @click="$emit('toggleDrawer')" icon>
      <v-icon>{{ drawer ? "mdi-close" : "mdi-menu" }}</v-icon>
    </v-btn>

    <v-spacer />

    <v-toolbar-title class="accent--text">
      <v-text-field @keyup="typingSearch" outlined rounded dense label="Buscar" color="primary"
        prepend-inner-icon="mdi-magnify" hide-details class="mr-5 pt-2" />
    </v-toolbar-title>

    <v-btn icon @click="$vuetify.theme.dark = !$vuetify.theme.dark" class="mt-2">
      <v-icon>{{
          $vuetify.theme.dark
            ? "mdi-white-balance-sunny"
            : "mdi-moon-waxing-crescent"
      }}</v-icon>
    </v-btn>
    <v-menu v-model="menu" :close-on-content-click="true" :nudge-width="200" offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-btn icon v-bind="attrs" v-on="on" class="mt-2">
          <v-badge :content="countInterests" :value="countInterests" color="green" overlap>
            <v-icon> mdi-inbox </v-icon>
          </v-badge>
        </v-btn>
      </template>
      <v-card>
        <v-list>
          <v-list-item>
            <v-list-item-avatar>
              <v-icon v-if="countInterests == 0">mdi-bell-outline</v-icon>
              <v-icon v-else>mdi-bell-badge-outline</v-icon>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title>{{ countInterests == 0 ? 'Sem Mensagens' : (countInterests == 1 ? `Você tem
                              ${countInterests} nova mensagem` : `Você tem ${countInterests} novas mensagens`)
              }}</v-list-item-title>
              <v-list-item-subtitle>
                <v-btn text small color="primary" @click="$router.push('/notifications')">
                  Ver Todas
                </v-btn>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>

        <v-divider></v-divider>

        <v-list>
          <v-list-item v-for="(interest, i) in interests" :key="i" :class="interest.viewed ? '' : 'list-interests'"
            @click="viewNotification(interest.id)">
            <v-list-item-avatar>
              <v-icon large>mdi-account-circle-outline</v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>{{ getName(interest.name) }} enviou uma mensagem
              </v-list-item-title>
              <v-list-item-subtitle>{{ interest.created_at_formated }}
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action> </v-list-item-action>
          </v-list-item>
        </v-list>
      </v-card>
    </v-menu>
    <v-btn icon @click="logout" class="mt-2">
      <v-icon> mdi-exit-to-app </v-icon>
    </v-btn>
  </v-app-bar>
</template>

<script>
import { eventbus } from "@/main.js";
export default {
  props: {
    drawer: Boolean,
  },
  data: () => ({
    menu: false,
    interests: {},
    countInterests: 0,
  }),
  methods: {
    logout() {
      this.$session.destroy();
      this.$cookies.remove("user_id");
      this.$cookies.remove("token");
      this.$router.push({ name: "login" });
    },
    viewNotification(id) {
      this.$router.push(`/notifications/${id}`).catch(
        location.href = `/notifications/${id}`
      )
    },
    getInterests() {
      this.$api
        .get("interests/news", {
          headers: {
            Authorization: `Bearer ${this.$cookies.get("token")}`,
          },
        })
        .then((res) => {
          this.countInterests = parseInt(res.data.total);
          this.interests = res.data.data;
        })
        .catch((err) => {
          console.error(err);
        });
    },
    typingSearch(e) {
      eventbus.typingSearch(e.target.value);
    },
    getName(name) {
      return name.split(" ")[0];
    },
  },
  mounted() {
    this.getInterests();
  },
};
</script>

<style scoped>
.list-interests {
  background: #ebebeb;
}
</style>